import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import {
  Helmet,
  I18nextContext,
  Link,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next'
import type { CoursesQuery } from '@graphql-types'
import { css, useTheme } from '@emotion/react'
import { useContext, useEffect } from 'react'
import SEO from '@components/SEO'
import Container from '@components/Container'
import FeaturedBy from '@components/FeaturedBy'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ButtonLink } from '@components/ButtonLink'
import { H2Highlight } from '@components/H2Highlight'
import CourseMode from '@components/CourseMode'
import styled from '@emotion/styled'
import { maxWidth } from 'src/themes'

const CourseBlock = styled(Link)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	background: hsla(0, 0%, 100%, 0.1);
	border-radius: 1rem;
	padding: 1rem 2rem;
	text-align: center;
	box-sizing: border-box;

	> div:first-of-type {
		margin: 1rem;
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	h4 {
		margin: 0;
	}
	p {
		margin: 0;
	}
`

const CoursesPage = (props: PageProps<CoursesQuery>) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mapCourse = props.data.course!
  const smaCourse = props.data.smaCourse!

  return (
    <Container>
      <SEO title={t('courses')} keywords={[]} />

      <h1>{t('courses')}</h1>

      <H2Highlight>{t('for_career_change')}</H2Highlight>
      <div
        css={css`
						display: grid;
						grid-template-columns: 1fr 1fr;
						gap: 1rem;

						${maxWidth[2]} {
							grid-template-columns: 1fr;
						}
					`}>
        <CourseBlock to='/courses/MAP/'>
          <div>
            <GatsbyImage
              image={
                mapCourse.imageSharp?.childImageSharp?.gatsbyImageData
              }
              alt={mapCourse.name}
            />
          </div>
          <CourseMode mode={mapCourse!.mode!} />
          <h4>{mapCourse.name}</h4>
          <p>{mapCourse.slogan}</p>
        </CourseBlock>

        <CourseBlock to='/courses/SMA/'>
          <div>
            <GatsbyImage
              image={
                smaCourse.imageSharp?.childImageSharp?.gatsbyImageData
              }
              alt={smaCourse.name}
            />
          </div>
          <CourseMode mode={smaCourse!.mode!} />
          <h4>{smaCourse.name}</h4>
          <p>{smaCourse.slogan}</p>
        </CourseBlock>
      </div>

      <div
        css={css`
					margin-top: 2rem;
					margin-bottom: 2rem;
				`}>
        <H2Highlight>{t('for_starter')}</H2Highlight>
        <div
          css={css`
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
						gap: 1rem;

						${maxWidth[2]} {
							grid-template-columns: 1fr;
						}
					`}>
          {props.data.flexCourses.nodes.map((course, i) => (
            <CourseBlock
              key={i}
              to={`/courses/FLEX/classes/${course.code}/`}>
              <GatsbyImage
                image={
                  course.iconSharp?.childImageSharp
                    ?.gatsbyImageData
                }
                alt={course.name!}
              />
              <CourseMode mode='night-time' />
              <h4>{course.name}</h4>
            </CourseBlock>
          ))}
          <CourseBlock to='/free/tecky-code/'>
            <div>
              <GatsbyImage
                image={
                  props.data.tecky_code_logo?.childImageSharp
                    ?.gatsbyImageData
                }
                alt={mapCourse.name!}
              />
            </div>
            <CourseMode mode='free' />
            <h4>{t('layout:tecky_code')}</h4>
          </CourseBlock>
          <CourseBlock to='/free/videos/'>
            <div>
              <GatsbyImage
                image={
                  props.data.videos_logo?.childImageSharp
                    ?.gatsbyImageData
                }
                alt={mapCourse.name!}
              />
            </div>
            <CourseMode mode='free' />
            <h4>{t('tecky_videos')}</h4>
          </CourseBlock>
        </div>
      </div>

      <H2Highlight>{t('for_professional_development')}</H2Highlight>
      <div
        css={css`
					display: grid;
					grid-template-columns: 1fr 1fr;
					gap: 1rem;

					${maxWidth[2]} {
						grid-template-columns: 1fr;
					}
				`}>
        {props.data.proCourses.nodes.map((course, i) => (
          <CourseBlock key={i} to={`/courses/${course.code}/`}>
            <div>
              <GatsbyImage
                image={
                  course.imageSharp?.childImageSharp
                    ?.gatsbyImageData
                }
                alt={course.name}
              />
            </div>
            <CourseMode mode='night-time' />
            <h4>{course.name}</h4>
            <p>{course.slogan}</p>
          </CourseBlock>
        ))}
      </div>
      <div css={css`
				margin-top: 4rem;
			`}>
        <ButtonLink to='/schedules/'>{t('schedules.view_title')}</ButtonLink>
      </div>
    </Container>
  )
}

export default CoursesPage

export const query = graphql`
	query Courses($language: String!) {
		...TranslationFragment
		course: cockpitCoursesCollection(
			_lang: { eq: $language }
			code: { eq: "MAP" }
		) {
			name
			slogan
			code
			mode
			image {
				path
			}
			imageSharp {
				childImageSharp {
					gatsbyImageData(width: 250, placeholder: BLURRED)
				}
			}
		}
		smaCourse: cockpitCoursesCollection(
			_lang: { eq: $language }
			code: { eq: "SMA" }
		) {
			name
			slogan
			code
			mode
			image {
				path
			}
			imageSharp {
				childImageSharp {
					gatsbyImageData(width: 250, placeholder: BLURRED)
				}
			}
		}
		proCourses: allCockpitCoursesCollection(
			filter: {
				_lang: { eq: $language }
				mode: { eq: "night-time" }
				active: { eq: true }
			}
			sort: { fields: order, order: ASC }
		) {
			nodes {
				name
				slogan
				code
				mode
				image {
					path
				}
				imageSharp {
					childImageSharp {
						gatsbyImageData(width: 250, placeholder: BLURRED)
					}
				}
			}
		}
		flexCourses: allCockpitFlexCoursesNewCollection(
			filter: { _lang: { eq: $language }, active: { eq: true } } # sort: { fields: order, order: ASC }
		) {
			nodes {
				name
				code
				iconSharp {
					childImageSharp {
						gatsbyImageData(width: 250, height: 250)
					}
				}
			}
		}
		tecky_code_logo: file(relativePath: { eq: "code-icons/logo.png" }) {
			childImageSharp {
				gatsbyImageData(width: 250, placeholder: BLURRED)
			}
		}
		videos_logo: file(relativePath: { eq: "5-min-programming.png" }) {
			childImageSharp {
				gatsbyImageData(width: 250, placeholder: BLURRED)
			}
		}
	}
`
